import React from 'react'
import { Link } from 'gatsby'
import { format } from 'date-fns'

// components
import { kebabCase } from 'lodash'
import { FiTag } from 'react-icons/fi'
import { IconContext } from 'react-icons'
import CoverImage from './CoverImage'
import { IGatsbyImageData } from 'gatsby-plugin-image'

// props interface
interface CardProps {
  title: string
  gatsbyImageData: IGatsbyImageData
  dateString: string
  postSlug: string
  tags: string[]
  description: string
}

const Card: React.FC<CardProps> = ({
  title,
  gatsbyImageData,
  dateString,
  postSlug,
  tags,
  description,
}) => {
  return (
    <article className="flex flex-row items-stretch transition-shadow duration-200 bg-white">
      <div className="flex flex-col flex-grow">
        <Link to={postSlug}>
          <h3
            className="inline underline py-1 px-4 m-1 text-tintBlack text-base sm:text-xl leading-5"
            style={{
              background: 'linear-gradient(transparent 70%, pink 70%)',
            }}
          >
            {title}
          </h3>
        </Link>

        <div className="text-sm sm:text-lg mt-1">
          <time dateTime={dateString}>
            {format(new Date(dateString), 'LLLL	d, yyyy')}
          </time>
        </div>

        <p className="text-sm sm:text-lg leading-relaxed mt-1">{description}</p>

        <div className="flex flex-wrap mt-2">
          {tags.map((tag: any) => (
            <div
              key={`${tag}tag`}
              className="flex m-2 bg-backgroundColor1 ring ring-backgroundColor2 rounded-lg px-2 py-1"
            >
              <Link to={`/tags/${kebabCase(tag)}/`}>
                <span className="flex flex-row text-tintBlack text-sm sm:text-lg">
                  <IconContext.Provider
                    value={{ className: 'text-md sm:text-xl mt-1' }}
                  >
                    <FiTag />
                  </IconContext.Provider>

                  <span className="text-tintBlack text-sm sm:text-lg">
                    &nbsp;{tag}
                  </span>
                </span>
              </Link>
            </div>
          ))}
        </div>
      </div>

      <div className="mx-1">
        <CoverImage
          title={`${title}-image`}
          gatsbyImageData={gatsbyImageData}
        />
      </div>
    </article>
  )
}

export default Card
