import React from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

interface CoverImageProps {
  title: string
  gatsbyImageData: IGatsbyImageData
}

const CoverImage: React.FC<CoverImageProps> = ({ title, gatsbyImageData }) => {
  return (
    <div className="sm:mx-0">
      <GatsbyImage
        image={gatsbyImageData}
        alt={`Cover Image for ${title}`}
        className="object-contain bg-white h-auto w-32"
      />
    </div>
  )
}

export default CoverImage
