import React from 'react'
import classNames from 'classnames'

// components
import Card from './Card'

// props interface
interface CardProps {
  articleEdges?: any
  children?: React.ReactNode
  className?: string
  showOnlyFirst?: boolean
}

const CardGrid: React.FC<CardProps> = ({
  articleEdges,
  children,
  className,
  showOnlyFirst = true,
}) => {
  const classes = classNames('', className)
  return (
    <div className={classes}>
      {showOnlyFirst ? <div /> : <div>{children}</div>}

      <div className="flex flex-col space-y-10">
        {articleEdges &&
          articleEdges.map(({ node }: any) => (
            <Card
              key={`article-${node.fields.slug}`}
              gatsbyImageData={
                node.frontmatter.featuredimage.childImageSharp.gatsbyImageData
              }
              postSlug={node.fields.slug}
              dateString={node.frontmatter.date}
              title={node.frontmatter.title}
              description={node.frontmatter.description}
              tags={node.frontmatter.tags}
            />
          ))}
      </div>

      <div>{children}</div>
    </div>
  )
}

export default CardGrid
